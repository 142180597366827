import _ from 'lodash'
import {
  GetSubscribersFieldEnhancer,
  GSInputLayout,
  GSLayout,
  ElementsLayout,
  GSExtraData,
  GSIframeRect,
} from './get-subscribers-types'
import {
  GSTextInputFields,
  isSkinWithFieldTitlesGS,
  isSkinFieldHeightFromContainerGS,
  isSideLabelSkinGS,
  isSkinWithMiddleInputLayoutGS,
} from './get-subscribers-utils'
import { getBorderSize } from './get-subscribers-style'
import { wrapEnhancerWithRoles } from '../contact-form-service'

export const EXTRA_WIDTH_SIDE_LABEL = 22
const getInputLayout = (
  { input, label, container }: GSInputLayout,
  settings: GetSubscribersSettings,
  { isMobile, isDropdown }: { isMobile?: boolean; isDropdown?: boolean },
) => {
  const withLabels = isSkinWithFieldTitlesGS(settings)
  const layoutFromInput = isSideLabelSkinGS(settings) || isSkinWithMiddleInputLayoutGS(settings)
  const containerComp = layoutFromInput ? input : container

  const inputHeight = isSkinFieldHeightFromContainerGS(settings) ? container?.height : input?.height

  let labelMargin = 7
  if (withLabels) {
    labelMargin =
      container.height - input.height - parseInt(label.extraStyles.fontSize.replace('px', ''))
  }
  const layout = getElementLayout(containerComp)
  // layout.height = inputHeight //TODO: check i didn't crash other stuff
  if (isSideLabelSkinGS(settings) && !isMobile) {
    if (!isDropdown) {
      layout.width = _.max([layout.width - EXTRA_WIDTH_SIDE_LABEL, 0])
    }
    layout.x += EXTRA_WIDTH_SIDE_LABEL
  }
  return {
    layout,
    inputHeight,
    labelMargin,
  }
}
const agreeBlockPaddingsByAppStyle = [
  [12, 20, 12],
  [0, 16, 12],
  [12, 0, 0],
  [12, 22, 12],
  [12, 22, 12],
]

const fixAgreeLayout = (
  layout,
  cbGSLayout: GSLayout,
  style: {
    appStyle: 0 | 1 | 2 | 3 | 4
    bodyAlignment: 0 | 1
  },
  borderSize: number,
) => {
  const paddings = agreeBlockPaddingsByAppStyle[style.appStyle]
  const cbMarginStyle =
    style.bodyAlignment === 1
      ? cbGSLayout.extraStyles.marginRight
      : cbGSLayout.extraStyles.marginLeft
  const cbMargin = parseInt(cbMarginStyle) - borderSize
  const rightBodyExtraMargin = style.bodyAlignment === 0 ? cbMargin : 0

  return {
    y: layout.y + paddings[0],
    x: layout.x + paddings[1] + rightBodyExtraMargin,
    height: layout.height - paddings[0] - paddings[2],
    width: layout.width - 2 * paddings[1] - cbMargin - rightBodyExtraMargin,
  }
}

const getAgreeLayout = (
  { container, checkbox }: { container: GSLayout; checkbox: GSLayout },
  { signupForm, style }: GetSubscribersSettings,
  { componentStyle }: GSExtraData,
  isMobile = false,
) => {
  const borderSize = getBorderSize(componentStyle)
  const layout = getElementLayout(container)
  layout.y = layout.y - borderSize

  layout.height = _.max([20 + 2 * borderSize, layout.height])

  if (signupForm.layout === 0 && !isMobile) {
    return fixAgreeLayout(layout, checkbox, style, borderSize)
  }

  return layout
}

export const getFieldLabelLayoutLocation = (
  fieldData: GetSubscribersFormField,
  { style }: GetSubscribersSettings,
) => {
  switch (fieldData.name) {
    case 'name':
      return 'name.label'
    case 'phone':
      const labelLayout = style.bodyAlignment === 0 ? 'phoneCode.label' : 'phoneNumber.label'
      return labelLayout
    case 'email':
      return 'email.label'
  }
}

const getFieldLayoutAndHeight = (
  fieldData: GetSubscribersFormField,
  layouts: ElementsLayout,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
  isMobile = false,
) => {
  switch (fieldData.name) {
    case 'name':
      return getInputLayout(layouts.name, settings, { isMobile })
    case 'phoneNumber':
      return getInputLayout(layouts.phoneNumber, settings, { isMobile })
    case 'phoneCode':
      return getInputLayout(layouts.phoneCode, settings, { isMobile, isDropdown: true })
    case 'email':
      return getInputLayout(layouts.email, settings, { isMobile })
    case 'agree':
      return { layout: getAgreeLayout(layouts.consent, settings, extraData, isMobile) }
    default:
      return { layout: {}, inputHeight: null, labelMargin: null }
  }
}

export const updateDesktopLayout: GetSubscribersFieldEnhancer = ({
  fieldData,
  settings,
  convertedField,
  extraData,
}): RawComponentStructure => {
  const { layout, inputHeight, labelMargin } = getFieldLayoutAndHeight(
    fieldData,
    extraData.desktopLayouts,
    settings,
    extraData,
  )
  convertedField.layout = _.merge({}, convertedField.layout, layout)
  if (_.includes(GSTextInputFields, fieldData.name)) {
    convertedField.props.inputHeight = inputHeight || 30
    convertedField.props.labelMargin = labelMargin || 7
  }
  return convertedField
}

export const updateMobileLayout: GetSubscribersFieldEnhancer = ({
  fieldData,
  settings,
  convertedField,
  extraData,
}): RawComponentStructure => {
  if (extraData.hiddenInMobile) {
    return convertedField
  }
  const { layout, inputHeight } = getFieldLayoutAndHeight(
    fieldData,
    extraData.mobileLayouts,
    settings,
    extraData,
    true,
  )
  if (_.includes(GSTextInputFields, fieldData.name)) {
    convertedField.props.inputHeightMobile = inputHeight
  }

  _.set(convertedField, 'mobileStructure.layout', _.merge({}, convertedField.layout, layout))
  return convertedField
}

export const updateAlignment: GetSubscribersFieldEnhancer = ({
  settings,
  convertedField,
  fieldData,
  skippedRoles,
}): RawComponentStructure => {
  const action = (field) => {
    const alignment = settings.style.bodyAlignment === 1 ? 'right' : 'left'
    if (fieldData.name === 'agree') {
      field.props.alignment = alignment
    }
    field.props.textAlignment = alignment
    return field as RawComponentStructure
  }
  return wrapEnhancerWithRoles(convertedField, skippedRoles, action)
}

export const updateAgreeTermsLayoutProps: GetSubscribersFieldEnhancer = ({
  fieldData,
  convertedField,
  extraData,
}) => {
  if (fieldData.name !== 'agree') {
    return convertedField
  }
  const borderSize = getBorderSize(extraData.componentStyle)
  convertedField.props.buttonSize = 20 + 2 * borderSize
  convertedField.props.spacing = 8 - borderSize
  convertedField.props.labelPadding = 50

  return convertedField
}

export const layoutThankYouMessage = (layouts: ElementsLayout) => {
  const { successTitle, successSubtitle, successView } = layouts
  return {
    width: successView.width,
    height: successSubtitle.height + successSubtitle.y - successTitle.y,
    x: successView.x,
    y: successTitle.y,
    scale: successTitle.scale,
  }
}

export const layoutPostStamp = (layout: GSLayout, postStampLayout: any) => {
  const { x: xCont, width: widthCont, y: yCont } = layout
  const x = xCont + widthCont - postStampLayout.width
  const y = yCont + 8
  return _.merge({}, postStampLayout, { x, y })
}

export const layoutStamp = (layout: GSLayout, stampLayout: any) => {
  const { x: xCont, width: widthCont } = layout
  const x = (xCont + widthCont - stampLayout.width) / 2 + 1
  return _.merge({}, stampLayout, { x })
}

export const getTitleLayoutLabel = (layouts: ElementsLayout , verticalName, horizontalName) => {
  const vertical = _.get(layouts, verticalName)
  const horizontal = _.get(layouts, horizontalName)
  return {
    ..._.pick(vertical,'y', 'height',  'scale'),
    ..._.pick(horizontal,'x', 'width'),
  }
}
export const getSideLabelLayout = (layouts: ElementsLayout, elementName) => {
  const label = _.get(layouts, elementName)
  const width = label.width + EXTRA_WIDTH_SIDE_LABEL
  return {
    ...getElementLayout(label),
    width,
  }
}

export const getElementLayout = (element: GSLayout) =>
  _.pick(element, 'x', 'y', 'height', 'width', 'scale')

export const getHeightAndWidthFromRect = ({ height, width }: GSIframeRect) => ({
  height,
  width,
})

export const mobileStructureAddition = (layoutCreator: Function, extraData: GSExtraData) => {
  return extraData.hiddenInMobile
    ? {}
    : {
        mobileStructure: { layout: layoutCreator() },
      }
}

const getComplexPhoneLayouts = (dropdownLayout, textLayout, optimizeWidth = true) => {
  const sameLine = textLayout.y === dropdownLayout.y
  const dropdownNewWidth = _.max([90, dropdownLayout.width])
 
  const widgetLayout = {
    x: dropdownLayout.x,
    y: dropdownLayout.y,
    height: textLayout.y + textLayout.height - dropdownLayout.y,
    width: textLayout.x + textLayout.width - dropdownLayout.x,
  }

  let moveText = 0
  if(sameLine){
    moveText = optimizeWidth ? dropdownNewWidth - dropdownLayout.width : 0
    textLayout.width -= moveText
  }

  textLayout.x -= dropdownLayout.x - moveText
  textLayout.y = sameLine ? 0 : textLayout.y - dropdownLayout.y
  dropdownLayout.x = 0
  dropdownLayout.y = 0

  if (optimizeWidth) {
    dropdownLayout.width = dropdownNewWidth
  }

  const containerLayout = {
    x: 0,
    y: 0,
    height: widgetLayout.height,
    width: widgetLayout.width,
  }

  return {
    dropdownLayout,
    textLayout,
    widgetLayout,
    containerLayout,
  }
}

export const updatePhoneDesktopLayout: GetSubscribersFieldEnhancer = ({
  settings,
  convertedField,
  extraData,
  getSubscribersForm,
}) => {
  const [container] = convertedField.components
  const [dropdown, text] = container.components

  const textWithLayout = updateDesktopLayout({
    fieldData: { name: 'phoneNumber', required: true }, //workaround
    settings,
    extraData,
    getSubscribersForm,
    convertedField: text,
  })

  const dropdownWithLayout = updateDesktopLayout({
    fieldData: { name: 'phoneCode', required: true }, //workaround
    settings,
    extraData,
    getSubscribersForm,
    convertedField: dropdown,
  })

  const { widgetLayout, containerLayout, dropdownLayout, textLayout } = getComplexPhoneLayouts(
    dropdownWithLayout.layout,
    textWithLayout.layout,
  )

  return _.merge({}, convertedField, {
    layout: widgetLayout,
    components: [
      {
        layout: containerLayout,
        components: [{ layout: dropdownLayout }, { layout: textLayout }],
      },
    ],
  })
}

export const updatePhoneMobileLayout: GetSubscribersFieldEnhancer = ({
  settings,
  convertedField,
  extraData,
  getSubscribersForm,
}) => {
  if (extraData.hiddenInMobile) {
    return convertedField
  }
  const [container] = convertedField.components
  const [dropdown, text] = container.components

  const textWithLayout = updateMobileLayout({
    fieldData: { name: 'phoneNumber', required: true }, //workaround
    settings,
    extraData,
    getSubscribersForm,
    convertedField: text,
  })

  const dropdownWithLayout = updateMobileLayout({
    fieldData: { name: 'phoneCode', required: true }, //workaround
    settings,
    extraData,
    getSubscribersForm,
    convertedField: dropdown,
  })

  const { widgetLayout, containerLayout, dropdownLayout, textLayout } = getComplexPhoneLayouts(
    dropdownWithLayout.mobileStructure.layout,
    textWithLayout.mobileStructure.layout,
    !isSideLabelSkinGS(settings),
  )

  return _.merge({}, convertedField, {
    mobileStructure: {
      layout: widgetLayout,
    },
    components: [
      {
        mobileStructure: {
          layout: containerLayout,
        },
        components: [
          {
            mobileStructure: {
              layout: dropdownLayout,
            },
          },
          {
            mobileStructure: {
              layout: textLayout,
            },
          },
        ],
      },
    ],
  })
}
